import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import find from "lodash.find"

// Import Global Context(s)
import UserContext from "~context/user"

/* Import Global Components */
import Page from "~components/page"
import PostPreview from "~components/postPreview"

/* Import Local Styles */
import "./report.scss"

const ReportTemplate = ({ data: { sanityReport: report } }) => {
  const [isReportSaved, setIsReportSaved] = useState(false)
  const [user, { saveReport, removeReport }] = useContext(UserContext)

  useEffect(() => {
    if (user && report) {
      const isReportSavedTemporary =
        find(user.research.reports, savedReport => {
          return savedReport.id === report.id
        }) !== undefined
      setIsReportSaved(isReportSavedTemporary)
    }
  }, [report, user])

  const handleSaveReport = () => {
    if (user) {
      saveReport(report)
    }
  }

  const handleRemoveReport = () => {
    if (user) {
      removeReport(report)
    }
  }

  return (
    <Page title={report.title}>
      <div>
        <p>{report.title}</p>
      </div>
      <div>
        {/* if user has saved this post, show remove option */}
        {isReportSaved ? (
          <button onClick={handleRemoveReport}>Remove Report</button>
        ) : (
          <button onClick={handleSaveReport}>Save Report</button>
        )}
      </div>
      <br />
      <div>
        <BlockContent blocks={report._rawDescription} />
      </div>
      {/* posts associated with report */}
      {report.posts && report.posts.nodes && report.posts.nodes.length > 0 ? (
        <div>
          <p>Associated Posts</p>
          {report.posts.nodes.map(post => (
            <PostPreview key={post._id} post={post} />
          ))}
        </div>
      ) : null}
    </Page>
  )
}

export default ReportTemplate

export const query = graphql`
  query($id: String!) {
    sanityReport(id: { eq: $id }) {
      id
      slug
      title
      _rawDescription
      posts {
        nodes {
          _id
          postAuthor {
            _id
            name
            slug
            publishOnWebsite
          }
          postTitle
          publicationDate
          _rawPostSummary
        }
      }
      heroImage {
        asset {
          _key
        }
      }
    }
  }
`
